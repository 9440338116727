export const dailyGameUrl = "https://wortle.envirotechie.com/";
export const safariZoneUrl = "https://wortle.envirotechie.com/";

export const trainerQuotes = [
  { quote: "Hey! You have #POKEMON! Come on! Let's battle'em!", trainer: "Bug Catcher" },
  { quote: "Yo! You can't jam out if you're a #POKEMON trainer!", trainer: "Bug Catcher" },
  { quote: "Hey, wait up! What's the hurry?", trainer: "Bug Catcher" },
  { quote: "Stop right there, kid! You're still light years from facing BROCK!", trainer: "Jr. Trainer" },
  { quote: "You looked at me, didn't you?", trainer: "Lass" },
  { quote: "Hey! I met you in VIRIDIAN FOREST!", trainer: "Bug Catcher" },
  { quote: "Hi! I like shorts! They're comfy and easy to wear!", trainer: "Youngster" },
  { quote: "Are you a trainer? Let's fight!", trainer: "Bug Catcher" },
  { quote: "Hey! You're not wearing shorts!", trainer: "Bug Catcher" },
  { quote: "You can fight my new #POKEMON!", trainer: "Bug Catcher" },
  { quote: "I came to get my mushroom #POKEMON!", trainer: "Lass" },
  { quote: "I'm more than good enough for you! MISTY can wait!", trainer: "Jr. Trainer" },
  { quote: "I just got down from MT.MOON, but I'm ready!", trainer: "Hiker" },
  { quote: "I'm off to see a #POKEMON collector at the cape!", trainer: "Hiker" },
  { quote: "I knew I had to fight you!", trainer: "Youngster" },
  { quote: "My friend has a cute #POKEMON. I'm so jealous!", trainer: "Lass" },
  { quote: "There aren't many bugs out here.", trainer: "Bug Catcher" },
  { quote: "I've never seen you around! Are you good?", trainer: "Bug Catcher" },
  { quote: "I travel alone on my journeys! My #POKEMON are my only friends!", trainer: "Gentleman" },
  { quote: "I collected these #POKEMON from all around the world!", trainer: "Lass" },
  { quote: "I love #POKEMON! Do you?", trainer: "Youngster" },
  { quote: "Hello stranger! Stop and chat! All my #POKEMON are from the sea!", trainer: "Fisherman" },
  { quote: "Matey, you're walking the plank if you lose!", trainer: "Sailor" },
  { quote: "Which do you like, a strong or a rare #POKEMON?", trainer: "Gentleman" },
  { quote: "I'm a lightweight, but I'm good with electricity!", trainer: "Rocker" },
  { quote: "You have #POKEMON with you! You're mine!", trainer: "Jr. Trainer" },
  { quote: "Who's that walking with those good looking #POKEMON?", trainer: "Jr. Trainer" },
  { quote: "I got up early every day to raise my #POKEMON from cocoons!", trainer: "Bug Catcher" },
  { quote: "Go, my super bug #POKEMON!", trainer: "Bug Catcher" },
  { quote: "I've been to a #POKEMON GYM a few times. But, I lost each time.", trainer: "Jr. Trainer" },
  { quote: "Wow, are you a POKEMANIAC too? Want to see my collection?", trainer: "Pokemaniac" },
  { quote: "#POKEMON fight! Ready, go!", trainer: "Pokemaniac" },
  { quote: "My #POKEMON techniques will leave you crying!", trainer: "Hiker" },
  { quote: "Hit me with your best shot!", trainer: "Hiker" },
  { quote: "Hahaha! Can you beat my power?", trainer: "Hiker" },
  { quote: "You have a POKEDEX? I want one too!", trainer: "Pokemaniac" },
  { quote: "You have #POKEMON! Let's start!", trainer: "Jr. #Pokemon" },
  { quote: "I came this far for #POKEMON!", trainer: "Jr. Trainer" },
  { quote: "Hi kid, want to see my #POKEMON?", trainer: "Pokemaniac" },
  { quote: "What's a cute, round and fluffy #POKEMON?", trainer: "Lass" },
  { quote: "You need a strategy to win at this!", trainer: "Super Nerd" },
  { quote: "I like NIDORAN, so I collect them!", trainer: "Lass" },
  { quote: "School is fun, but so are #POKEMON.", trainer: "Super Nerd" },
  { quote: "MEOWTH is so cute, meow, meow, meow!", trainer: "Lass" },
  { quote: "BOSS said you can see GHOSTs with the SILPH SCOPE!", trainer: "Rocket" },
  { quote: "Stop meddling in TEAM ROCKET's affairs!", trainer: "Rocket" },
  { quote: "I know you! You ruined our plans at MT.MOON!", trainer: "Rocket" },
  { quote: "Don't bring any bugs or fire #POKEMON in here!", trainer: "Lass" },
  { quote: "I hear you're good! Show me!", trainer: "Blackbelt" },
  { quote: "Nothing tough frightens me! I break boulders for training!", trainer: "Blackbelt" },
  { quote: "My #POKEMON are my loyal soldiers!", trainer: "Scientist" },
  { quote: "Show TEAM ROCKET a little respect!", trainer: "Rocket" },
  { quote: "We study POKE BALL technology on this floor!", trainer: "Scientist" },
  { quote: "You dare betray TEAM ROCKET?", trainer: "Rocket" },
  { quote: "Your #POKEMON seem to adore you, kid!", trainer: "Rocket" },
  { quote: "You know that power alone isn't enough!", trainer: "Psychic" },
  {
    quote: "SAFFRON #POKEMON GYM is famous for its psychics! You want to see SABRINA! I can tell!",
    trainer: "Psychic",
  },
  {
    quote: "#POKEMON take on the appearance of their trainers. Your #POKEMON must be tough, then!",
    trainer: "Channeler",
  },
  { quote: "You and I, our #POKEMON shall fight!", trainer: "Channeler" },
  { quote: "I just became a trainer! But, I think I can win!", trainer: "Youngster" },
  { quote: "Fwahaha! I have never lost!", trainer: "Gambler" },
  { quote: "Be patient! Fishing is a waiting game!", trainer: "Fisherman" },
  { quote: "I'd rather be working!", trainer: "Fisherman" },
  { quote: "Electricity is my specialty!", trainer: "Rocker" },
  { quote: "You never know what you could catch!", trainer: "Fisherman" },
  { quote: "My bird #POKEMON want to scrap!", trainer: "Bird Keeper" },
  { quote: "Wow! Your BADGEs are too cool!", trainer: "Jr. Trainer" },
  { quote: "My cute #POKEMON wish to make your acquaintance.", trainer: "Jr. Trainer" },
  { quote: "I always go with bird #POKEMON!", trainer: "Bird Keeper" },
  { quote: "You need to use TMs to teach good moves to #POKEMON!", trainer: "Bird Keeper" },
  { quote: "My bird #POKEMON should be ready for battle.", trainer: "Bird Keeper" },
  { quote: "Have you taught your bird #POKEMON how to FLY?", trainer: "Bird Keeper" },
  { quote: "Have you heard of the legendary #POKEMON?", trainer: "Bird Keeper" },
  { quote: "#POKEMON fight? Cool! Rumble!", trainer: "Biker" },
  { quote: "What's cool? Trading #POKEMON!", trainer: "Jr. Trainer" },
  { quote: "You look gentle, so I think I can beat you!", trainer: "Jr. Trainer" },
  { quote: "Let me try out the #POKEMON I just got in a trade!", trainer: "Jr. Trainer" },
  { quote: "When I whistle, I can summon bird #POKEMON!", trainer: "Bird Keeper" },
  { quote: "I always check every grassy area for new #POKEMON.", trainer: "Bird Keeper" },
  {
    quote: "Strength isn't the key for #POKEMON! It's strategy! I'll show you how strategy can beat brute strength!",
    trainer: "Juggler",
  },
  { quote: "I tried diving for #POKEMON, but it was a no go!", trainer: "Swimmer" },
  { quote: "Why are you riding a #POKEMON? Can't you swim?", trainer: "Swimmer" },
  { quote: "I rode my bird #POKEMON here!", trainer: "Bird Keeper" },
  { quote: "Do you know how hot #POKEMON fire breath can get?", trainer: "Super Nerd" },
  { quote: "You can't win! I have studied #POKEMON totally!", trainer: "Super Nerd" },
  { quote: "I just like using fire #POKEMON!", trainer: "Burglar" },
  { quote: "Fire is weak against H2O!", trainer: "Super Nerd" },
  { quote: "I caught all my #POKEMON at sea!", trainer: "Swimmer" },
  { quote: "Your #POKEMON will cower at the crack of my whip!", trainer: "Tamer" },
  { quote: "I can see you're good! Let me see exactly how good!", trainer: "Cooltrainer" },
  { quote: "VICTORY ROAD is the final test for trainers!", trainer: "Blackbelt" },
  { quote: "Ah, so you wish to challenge the ELITE FOUR?", trainer: "Juggler" },
  { quote: "If you can get through here, you can go meet the ELITE FOUR!", trainer: "Pokemaniac" },
  { quote: "Trainers live to seek stronger opponents!", trainer: "Cooltrainer" },
  { quote: "Hi, do you have a PIKACHU?", trainer: "Lass" },
  { quote: "I'm gonna be the best. You just can't beat me!", trainer: "Bug Catcher" },
  { quote: "I aim to be the ultimate trainer!", trainer: "Youngster" },
  { quote: "Psychic #POKEMON fear only bugs!", trainer: "Jr. Trainer" },
  // { quote: "", trainer: "" },
];
